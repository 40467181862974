import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";

import SearchBlock from "components/search/SearchBlock";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import { autobind } from "react-decoration";
import { page } from "components/page";
import { navigate } from "gatsby";

const getContent = gql`
  query getContent($form: ContentForm) {
    getContent(Input: $form) {
      sn
      content
      name
      langcode
    }
  }
`;

@withI18next(["common"])
@page
class content extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      titleName :"",
      langcode : ""
    };

  }

  render() {

    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <a class="accesskey" href="#aC" id="aC" accesskey="C" title="主要內容區">:::</a>
          <SearchBlock />
          <section className="main_content">
            <div className="container">

              <h2>{ this.state.langcode==="" ? this.state.titleName : this.props.t(this.state.langcode) }</h2>

              <ApolloProvider client={client.jumperrwdClient}>
              <Query 
                displayName="graphql" 
                query={getContent} 
                variables={{
                  form: {
                    sn : this.props.search.id
                  }
                }}
              >
                {({ loading, data, refetch, error }) => {
                  if (error) return "error";
                  if (loading) return "Loading...";

                  if (data.getContent !== null){

                    if (this.state.titleName !== data.getContent.name || this.state.langcode !== data.getContent.langcode) {
                      this.setState({
                        titleName: data.getContent.name,
                        langcode: data.getContent.langcode
                      })
                    }

                    return (
                      <div className="detail_block">
                      <p dangerouslySetInnerHTML={{
                        __html: data.getContent.content,
                      }}></p>
                      </div>
                    )
                  }else return "Error...";
                }}
              </Query>
              </ApolloProvider>

            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default content;
